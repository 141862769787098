import React, { Component } from "react";
// import cmsRequest from "../../api/cmsRequest";
import Footer from "../../components/Footer"
import logoImg from '../../assets/braun/logo.jpg'
import mainImg from '../../assets/braun/main.jpg'
import iconImg1 from '../../assets/braun/icon1.jpg'
import iconImg2 from '../../assets/braun/icon2.jpg'
import iconImg3 from '../../assets/braun/icon3.jpg'
import iconImg4 from '../../assets/braun/icon4.jpg'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

class Braun extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logoImgItem: {},
            mainImgItem: {},
            iconItem: [],
            year: 2024,
            ICPCodeItem: {},
            recordCodeItem: {}
        };
    }

    componentDidMount() {
        document.title = '博朗官网'

        // cmsRequest.request({ mid: '278f7cf16e' })
        //     .then(result => {
        //         let cmsData = result.data
        //         let logoImgItem = cmsData.find(item => item.id === '689d9466f3').content[0]
        //         let mainImgItem = cmsData.find(item => item.id === '872e97a125').content[0]
        //         let iconItem = cmsData.find(item => item.id === '2bf554d95d')
        //         console.log(iconItem)
        //         let ICPCodeItem = cmsData.find(item => item.id === 'f7efa8e58b')
        //         let recordCodeItem = cmsData.find(item => item.id === '3cd4ba31db')
        //         this.setState({
        //             logoImgItem,
        //             mainImgItem,
        //             iconItem,
        //             ICPCodeItem,
        //             recordCodeItem
        //         })
        //     })
        //     .catch(err => {
        //         console.log('data catch', err)
        //     })

        // cmsRequest.request({ mid: '494d6de189' })
        //     .then(result => {
        //         let cmsData = result.data
        //         let year = cmsData.find(item => item.id === '63a92cfade').content
        //         this.setState({
        //             year
        //         })
        //     })
        //     .catch(err => {
        //         console.log('year catch', err)
        //     })
        let logoImgItem = {
            isShow: true,
            imageUrl: logoImg,
            parameters: [
                {
                    value: '博朗Logo'
                }
            ]
        }
        let mainImgItem = {
            isShow: true,
            imageUrl: mainImg,
            parameters: [
                {
                    value: '德国博朗品牌代言人 王嘉尔，官网升级中，敬请期待。如果在此期间您需要任何关于产品购买及维修等的支持帮助，请联系我们，我们将竭诚为您服务。'
                }
            ]
        }
        let iconItem = {
            isShow: true,
            content: [
                {
                    isShow: true,
                    imageUrl: iconImg1,
                },
                {
                    isShow: true,
                    imageUrl: iconImg2,
                },
                {
                    isShow: true,
                    imageUrl: iconImg3,
                },
                {
                    isShow: true,
                    imageUrl: iconImg4,
                }
            ]

        }
        let ICPCodeItem = {
            isShow: true,
            content: '粤ICP备05017514号',
            clickActionParameters: {
                url: 'https://beian.miit.gov.cn/#/Integrated/index'
            }
        }
        let recordCodeItem = {
            isShow: true,
            content: '粤公网安备44011602000392号',
            clickActionParameters: {
                url: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011602000392'
            }
        }
        this.setState({
            logoImgItem,
            mainImgItem,
            iconItem,
            ICPCodeItem,
            recordCodeItem
        })
    }

    render() {
        let { logoImgItem, mainImgItem, iconItem, ICPCodeItem, recordCodeItem, year } = this.state
        return (
            <div className="homePage braun">
                <div className="container">
                    {
                        logoImgItem.isShow && (
                            <img className="logoImg" src={logoImgItem.imageUrl} alt={logoImgItem.parameters[0].value}></img>
                        )
                    }
                    {
                        mainImgItem.isShow && (
                            <img className="mainImg" src={mainImgItem.imageUrl} alt={mainImgItem.parameters[0].value}></img>
                        )
                    }
                    {
                        iconItem.isShow && (
                            <div className="bottomBox">
                                {
                                    iconItem.content[0].isShow && (
                                        <div className="item">
                                            <div className="itemTop">
                                                <img alt="icon" className="icon" src={iconItem.content[0].imageUrl}></img>
                                                线上购买
                                            </div>
                                            <div className="itemBottom">
                                                <div className="bottomText">
                                                    <span>博朗个人护理京东自营官方旗舰店&nbsp;</span>
                                                    <a target="_blank" href="https://braun.jd.com/" rel="noopener noreferrer">点击前往&nbsp;&gt;</a>
                                                </div>
                                                <div className="bottomText">
                                                    <span>博朗天猫官方旗舰店&nbsp;</span>
                                                    <a target="_blank" href="https://braun.tmall.com/" rel="noopener noreferrer">点击前往&nbsp;&gt;</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    iconItem.content[1].isShow && (
                                        <div className="item">
                                            <div className="itemTop">
                                                <img className="icon" src={iconItem.content[1].imageUrl} alt="icon"></img>
                                                寻找线下柜台
                                            </div>
                                            <div className="itemBottom">
                                                <div className="bottomText">
                                                    <Link to="/storeAddress">点击查看&nbsp;&gt;</Link>
                                                    {/*<a target="_blank" href="https://www.braun.com.cn/storeAddress" rel="noopener noreferrer">点击查看&nbsp;&gt;</a>*/}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    iconItem.content[2].isShow && (
                                        <div className="item">
                                            <div className="itemTop">
                                                <img className="icon" src={iconItem.content[2].imageUrl} alt="icon"></img>
                                                客户关怀中心
                                            </div>
                                            <div className="itemBottom">
                                                <div className="bottomText">
                                                    <span>时间：周一至周五 09：00-18：00&nbsp;</span>
                                                </div>
                                                <div className="bottomText">
                                                    <span>电话：400&nbsp;833&nbsp;3010&nbsp;</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    iconItem.content[3].isShow && (
                                        <div className="item">
                                            <div className="itemTop">
                                                <img className="icon" src={iconItem.content[3].imageUrl} alt="icon"></img>
                                                售后维修站
                                            </div>
                                            <div className="itemBottom">
                                                <div className="bottomText">
                                                    <Link to="/ServiceAddress">点击查看&nbsp;&gt;</Link>
                                                    {/*<a target="_blank" href="https://www.braun.com.cn/serviceAddress" rel="noopener noreferrer">点击查看&nbsp;&gt;</a>*/}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                {
                    ICPCodeItem.isShow && recordCodeItem.isShow && (
                        <Footer ICPCodeItem={ICPCodeItem} recordCodeItem={recordCodeItem} year={year}></Footer>
                    )
                }
            </div>
        )
    }
}

export default Braun;
